
import { defineComponent } from 'vue';
import FreightCodeList from '@/modules/master-data/views/config/FreightCodeList.vue';

export default defineComponent({
    name: 'freight-code-config',
    components: { FreightCodeList },
    setup() {
        return {};
    },
});
