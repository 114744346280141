import { useNotification } from '@/composable/useNotifications';
import FreightCode from '@/domain/FreightCode';
import FreightCodeApiService from '@/modules/master-data/services/api/FreightCodeApiService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class FreightCodeService {
    private notification = useNotification();

    private store = masterDataStore.getInstance().configStore;

    private freightCodeApiService: FreightCodeApiService;

    constructor() {
        this.freightCodeApiService = new FreightCodeApiService();
    }

    public async addNewFreightCode(freightCode: FreightCode): Promise<boolean> {
        const response = await this.freightCodeApiService.addNewFreightCode(freightCode);
        if (!response.success) {
            return false;
        }
        const newFreightCode = new FreightCode({
            ...freightCode,
            id: response.data,
        });
        this.store.addFreightCode(newFreightCode);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${freightCode.description}`);
        return true;
    }

    public async updateFreightCode(freightCode: FreightCode): Promise<boolean> {
        const response = await this.freightCodeApiService.updateFreightCode(freightCode);
        if (!response.success) {
            return false;
        }
        this.store.updateFreightCode(freightCode);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${freightCode.description}`);
        return true;
    }

    public async deleteFreightCode(freightCode: FreightCode): Promise<boolean> {
        const response = await this.freightCodeApiService.deleteFreightCode(freightCode);
        if (!response.success) {
            return false;
        }
        this.store.deleteFreightCode(freightCode);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${freightCode.description}`);
        return true;
    }
}
