
import { computed, defineComponent, reactive } from 'vue';
import FreightCodeService from '@/modules/master-data/services/FreightCodeService';
import FreightCode from '@/domain/FreightCode';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { TableDefinition } from '@/types';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    itemEdit: FreightCode;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'freight-code-list',
    components: { BSpinner },
    setup() {
        const freightCodeService = new FreightCodeService();
        const store = masterDataStore.getInstance().configStore;

        const { validateForm, validationResult, clearResults } = useValidator<FreightCode>('freight-code');

        const state = reactive<State>({
            showForm: false,
            itemEdit: new FreightCode(),
            modalTitle: '',
            saving: false,
        });

        const table = computed(
            (): TableDefinition<FreightCode> => ({
                items: store.freightCodes,
                key: 'freightCodeList',
                name: getTitleCaseTranslation('core.common.freightCodes'),
                columnDefinition: [
                    {
                        key: 'description',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.description'),
                    },
                ],
            }),
        );

        function openAdd() {
            clearResults();
            state.itemEdit = new FreightCode();
            state.modalTitle = getTitleCaseTranslation('core.common.newFreightCode');
            state.showForm = true;
        }

        function openEdit(item: FreightCode) {
            clearResults();
            state.itemEdit = new FreightCode(item);
            state.modalTitle = getTitleCaseTranslation('core.common.editFreightCode');
            state.showForm = true;
        }

        async function openFreightCodeHistory(item: FreightCode) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.FREIGHT_CODE, entityId: item.id },
            });
        }

        async function save() {
            validateForm(state.itemEdit);

            if (validationResult.isValid) {
                state.saving = true;
                let response: boolean;
                if (state.itemEdit.id > 0) {
                    response = await freightCodeService.updateFreightCode(state.itemEdit);
                } else {
                    response = await freightCodeService.addNewFreightCode(state.itemEdit);
                }
                if (response) {
                    state.showForm = false;
                }
                state.saving = false;
            }
        }

        return {
            table,
            openAdd,
            openEdit,
            openFreightCodeHistory,
            save,
            state,
            validationResult,
            getTitleCaseTranslation,
        };
    },
});
